import React from 'react'
import Layout from '../components/layout'
import Hero from '../components/hero'
import { StaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'

import videoSrc from '../assets/home-trim.mp4' // Static video file for this page
class ThanksPage extends React.Component {
    
    render() {  
        return (
            
            <Layout containerClass="thanks">
                <Hero title="Thanks For getting in touch" video={videoSrc} mobile={this.props.data.header.childImageSharp.fluid}/>
            </Layout>
        )
    }
}

export default ThanksPage


export const query = graphql`
  query thanksQuery {
    header:file(relativePath: { eq: "home/con.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
        }
    }
}
`;